<template>
  <v-container>
    <v-row>
      <v-col col="12" sm="12" md="12">
        <h2>
          Learning and development interventions/training programs attended
        </h2>
        <v-divider></v-divider>
      </v-col>
      <v-col col="12" sm="12" md="12">
        <v-btn color="primary" class="mr-4 text--white" @click="openDialog()"
          >Add Info...</v-btn
        >
      </v-col>
      <v-col col="12" sm="12" md="12">
        <v-data-table :headers="headers" :items="desserts" class="elevation-1">
          <template v-slot:item="row">
            <tr>
              <td nowrap>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  @click="updates(row.item)"
                >
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="red"
                  @click="deletes(row.item)"
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </td>
              <td>{{ row.item.Training }}</td>
              <td>{{ row.item.DFrom }}</td>
              <td>{{ row.item.DTo }}</td>
              <td>{{ row.item.NoHours }}</td>
              <td>{{ row.item.LD }}</td>
              <td>{{ row.item.ConductedBy }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Information</span>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="Training"
                      label="Training"
                      :rules="[
                        vv.init(this.Training),
                        vv.required(),
                        vv.maxlen(255),
                      ]"
                      auto-grow
                      outlined
                      dense
                      rows="1"
                      row-height="15"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="DFrom"
                      label="From"
                      type="date"
                      :rules="[
                        vv.init(this.DFrom),
                        vv.requiredDate()
                      ]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="DTo"
                      label="To"
                      type="date"
                      :rules="[
                        vv.init(this.DTo),
                        vv.requiredDate()
                      ]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="NoHours"
                      label="No. of Hours"
                      :rules="[
                        vv.init(this.NoHours),
                        vv.required(),
                        vv.maxlen(255),
                      ]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="LD"
                      label="Type of LD"
                      :rules="[vv.init(this.LD), vv.required(), vv.maxlen(255)]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="ConductedBy"
                      label="Conducted By"
                      :rules="[
                        vv.init(this.ConductedBy),
                        vv.required(),
                        vv.maxlen(255),
                      ]"
                      auto-grow
                      outlined
                      dense
                      rows="1"
                      row-height="15"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >Close</v-btn
            >
            <v-btn
              :disabled="!valid"
              color="blue darken-1"
              text
              @click="savechanges()"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import vldn from "@/js/validation";
export default {
  name: "Lad",
  data: () => ({
    valid: false,
    formTitle: "",
    dialog: false,
    headers: [
      {
        text: "Action",
        align: "start",
        sortable: false,
        value: "action",
      },
      { text: "Learning and Development", sortable: false, value: "Training" },
      { text: "From", sortable: false, value: "DFrom" },
      { text: "To", sortable: false, value: "DTo" },
      { text: "No. of Hours", sortable: false, value: "NoHours" },
      { text: "Type of LD", sortable: false, value: "LD" },
      { text: "Conducted By", sortable: false, value: "ConductedBy" },
    ],
    desserts: [],
    ID: 0,
    Training: "",
    DFrom: "",
    DTo: "",
    NoHours: "",
    LD: "",
    ConductedBy: "",
    vv: vldn.methods,
  }),
  componets: {
    vldn,
  },
  mounted() {
    this.$api.methods.init();
    this.getlad();
  },
  methods: {
    openDialog() {
      this.ID = 0;
      this.dialog = true;
    },
    updates(item) {
      this.ID = item.ID;
      this.Training = item.Training;
      this.DFrom = item.DFrom;
      this.DTo = item.DTo;
      this.NoHours = item.NoHours;
      this.LD = item.LD;
      this.ConductedBy = item.ConductedBy;

      this.dialog = true;
    },
    getlad() {
      this.$axios({
        method: "get",
        url: process.env.VUE_APP_BASE_URL + "pds/lad",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
      })
        .then((response) => {
          if (response) {
            this.desserts = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
    deletes(item) {
      this.$axios({
        method: "post",
        url: process.env.VUE_APP_BASE_URL + "pds/deletelad",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
        data: {
          ID: item.ID,
        },
      })
        .then((response) => {
          if (response) {
            this.getlad();
            this.ID = 0;
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
    savechanges() {
      if (this.$refs.form.validate()) {
        this.$axios({
          method: "post",
          url: process.env.VUE_APP_BASE_URL + "pds/updatelad",
          headers: {
            Authorization: "Bearer" + localStorage.getItem("usertoken"),
          },
          data: {
            ID: this.ID,
            Training: this.Training,
            DFrom: this.DFrom,
            DTo: this.DTo,
            NoHours: this.NoHours,
            Nature: this.Nature,
            LD: this.LD,
            ConductedBy: this.ConductedBy,
          },
        })
          .then((response) => {
            if (response) {
              this.getlad();
              this.ID = 0;
              this.Training = "";
              this.DFrom = "";
              this.DTo = "";
              this.NoHours = "";
              this.LD = "";
              this.ConductedBy = "";
              this.dialog = false;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 500) {
                //
              }
            }
          });
      }
    },
  },
};
</script>
<style>
.container {
  max-width: 80%;
}
</style>
